// List Articles
export const ArticlesDocument = gql`
  query Articles($site_id: bigint) {
    article_article(
      order_by: [{ is_pinned: desc_nulls_last }, { id: desc }]
      where: { site_id: { _eq: $site_id } }
    ) {
      id
      image_id
      title
      slug
      description
      published_at
      is_pinned
      is_archive
    }
  }
`
export function useArticlesQuery(site_id: number) {
  const _site_id = site_id ?? useRuntimeConfig().public.SITE_ID

  return useAsyncQuery(ArticlesDocument, {
    site_id: _site_id,
  })
}

// One article
export const ArticleDocument = gql`
  query Article($slug: String, $with_editor: Boolean!) {
    article_article(where: { slug: { _eq: $slug } }) {
      id
      image_id
      title
      content
      editor @include(if: $with_editor) {
        id
        integration_id
        first_name
        last_name
        email
      }
      is_editing @include(if: $with_editor)
      seconds_after_updated @include(if: $with_editor)
    }
  }
`
export function useArticleQuery(variables: {
  slug: string
  with_editor: boolean
}) {
  return useAsyncQuery(ArticleDocument, variables)
}

// Update article
export const ArticleUpdateDocument = gql`
  mutation ArticleUpdate($id: bigint!, $content: jsonb) {
    update_article_article_by_pk(
      pk_columns: { id: $id }
      _set: { content: $content }
    ) {
      id
      editor_id
      content
    }
  }
`
export function useArticleUpdate(getId: () => { variables: { id: number } }) {
  return useMutation(ArticleUpdateDocument, getId)
}

// Delete article
export const ArticleDelDocument = gql`
  mutation delArticle($id: bigint!) {
    delete_article_article_by_pk(id: $id) {
      id
    }
  }
`
export function useArticleDel() {
  return useMutation(ArticleDelDocument)
}

// Upsert article
export const ArticleUpsertDocument = gql`
  mutation ArticleUpsert(
    $article: article_article_insert_input!
    $columns: [article_article_update_column!]!
  ) {
    insert_article_article_one(
      object: $article
      on_conflict: {
        constraint: article_article_pkey
        update_columns: $columns
      }
    ) {
      id
    }
  }
`
export function useArticleUpsert() {
  return useMutation(ArticleUpsertDocument)
}
